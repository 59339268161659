// テーブルのスクロールバー
$(document).ready(function(){
  const scroll_w = $('#scrollbox .table').width();
  $('#scrollbar .inner').width(scroll_w);
  //$('#wrapper').width(scroll_w);
});
'use strict';
$("#scrollbar, #scrollbox").on('scroll', function() {
  if ( $(this).attr('id') === 'scrollbar' ) {
    $('#scrollbox').scrollLeft($(this).scrollLeft());
  } else {
    $("#scrollbar").scrollLeft($(this).scrollLeft());
  }
});

