import $ from "jquery";
$(document).ready(function(){
  $('.gutter').addClass('sticky-top');
  /*
  if(sessionStorage.getItem('memo_box_stts') == 'close'){
    $('.memo_box').addClass('memo_box_none');
    $('.gutter').addClass('memo_box_none');
    $('.memo_box').toggleClass('memo_disable');
    $('.gutter').toggleClass('memo_disable');
    $('.memo_icon_open').toggleClass('memo_icon_disable');
    $('.memo_icon_close').toggleClass('memo_icon_disable');
  }else if(sessionStorage.getItem('memo_box_stts') == 'open'){
    $('#memo_contents').innerWidth(sessionStorage.getItem('memo_width'));
  }
  */
  $('.memo_box').addClass('memo_box_none');
  $('.gutter').addClass('memo_box_none');
  $('.memo_box').toggleClass('memo_disable');
  $('.gutter').toggleClass('memo_disable');
  $('.memo_icon_open').toggleClass('memo_icon_disable');
  $('.memo_icon_close').toggleClass('memo_icon_disable');
  
  // メモ欄アイコンクリック
  $('#btn_memo').click(function() {
    if($('.memo_box').hasClass('memo_box_none')){
      $('.memo_box').removeClass('memo_box_none');
      $('.gutter').removeClass('memo_box_none');
    }
    $('.memo_box').toggleClass('memo_disable');
    $('.gutter').toggleClass('memo_disable');
    $('.memo_icon_open').toggleClass('memo_icon_disable');
    $('.memo_icon_close').toggleClass('memo_icon_disable');
    if($('.memo_box').hasClass('memo_disable')){
	  $("#flex-0").addClass("width100");
    }else{
	  $("#flex-0").removeClass("width100");
    }
  });
  $(window).on('beforeunload', function(){
    if($('.memo_box').hasClass('memo_disable')){
      sessionStorage.setItem('memo_box_stts', 'close');
    }else{
      sessionStorage.setItem('memo_box_stts', 'open');
      const v = $('#memo_contents').innerWidth();
      sessionStorage.setItem('memo_width', v - 12);
    }
  });
});
